import { toast } from "react-toastify";

export default class ToastService {
    showSuccess(message: string) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
        });
    }

    showError(message: string) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
        });
    }

    showWarning(message: string) {
        toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
        });
    }

    showInfo(message: string) {
        toast.info(message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
        });
    }
}