import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface ISearchBarProps {
  placeholder: string;
  onChange?: (value: string) => void;
  className?: string;
}

export default function SearchBar(props: ISearchBarProps) {
  const [search, setSearch] = useState("");

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const data = event.target.value;
    setSearch(data);
  };

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      if (props.onChange) {
        props.onChange(search);
      }
    }, 300);
    return () => clearTimeout(searchTimeOut)
    // eslint-disable-next-line
  }, [search])

  return (
    <div className={"search-box " + (props.className ?? "")}>
      <FontAwesomeIcon icon={faSearch} className="fa-search" />
      <input type='search' className='form-control' placeholder={props.placeholder} onChange={handleValueChange} value={search} />
    </div>
  );
}