import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
interface IIconProps {
    iconType: IconProp,
    headerClass: string,
    color: string
}
export default function IconElement(props: IIconProps) {
    return (
        <div className={"header-icon " + props.headerClass}>
            <FontAwesomeIcon
                icon={props.iconType}
                size="sm"
                color={props.color}
            />
        </div>
    )
}